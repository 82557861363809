<template>
    
<svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28 26H4.00002C3.26364 26 2.66669 26.597 2.66669 27.3333C2.66669 28.0697 3.26364 28.6667 4.00002 28.6667H28C28.7364 28.6667 29.3334 28.0697 29.3334 27.3333C29.3334 26.597 28.7364 26 28 26Z" fill="#7B7E8C"/>
<path d="M21.8133 16H20.3333V8.68001C20.3333 8.32639 20.1929 7.98725 19.9428 7.7372C19.6928 7.48716 19.3536 7.34668 19 7.34668H13C12.6464 7.34668 12.3072 7.48716 12.0572 7.7372C11.8071 7.98725 11.6667 8.32639 11.6667 8.68001V16H10.1867C9.96621 16.0044 9.75156 16.0714 9.56786 16.1934C9.38416 16.3154 9.23903 16.4872 9.14948 16.6887C9.05992 16.8902 9.02966 17.113 9.06222 17.3311C9.09478 17.5492 9.18883 17.7535 9.33334 17.92L15.1467 24.2667C15.3946 24.5031 15.7241 24.6349 16.0667 24.6349C16.4093 24.6349 16.7387 24.5031 16.9867 24.2667L22.8 17.92C22.9538 17.7451 23.0513 17.5279 23.0799 17.2968C23.1085 17.0656 23.0669 16.8312 22.9604 16.6241C22.854 16.4169 22.6876 16.2466 22.483 16.1353C22.2784 16.024 22.0451 15.9769 21.8133 16Z" fill="#7B7E8C"/>
</svg>

</template>

<script>
export default {
  name: "DownloadIcon",
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 30,
    },
  },
};
</script>
